import {useGetManyReference} from "react-admin";
import {Alert, Box, CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import SmallSquareData from "../../../component/presenter/square/SmallSquareData";
import * as React from "react";
import {useState} from "react";

const BreadcrumbOrderPreparationItem = (props) => {
    const [userSelectPreparationItem, setUserSelectPreparationItem] = useState(false);
    const {data, ids, loading, error} = useGetManyReference(
        'order_preparation_items',
        'orderPreparation.id',
        props.orderPreparation.id,
        {page: 1, perPage: 50},
        { field: 'priority', order: 'ASC' },
        {},
        'order_preparations',
    );


    if (loading) return <CircularProgress size='1rem'/>;
    if (error) return <Alert severity="error">Erreur lors de la récupération des items.</Alert>

    
    const dataSort = (Object.keys(data).map(key => {
        return data[key]
    }))

    if(dataSort.length > 0 && !userSelectPreparationItem){
        let dataNotPrepared = dataSort.filter( (data) => {
            return data.prepared['percent'] < 100;
        })
        if(dataNotPrepared.length > 0)
            setUserSelectPreparationItem(true)
            setTimeout(() => {
                props.setCurrentOrderPreparationItem(dataNotPrepared[0])
            }, 100)
    }
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
            <Box sx={{fontWeight: 'bold'}}>Emplacements et quantités à préparer</Box>
            <Box sx={{display: 'inline-flex', gap: 2}}>
                {dataSort.map((data, index) => {
                        const finished = data.prepared['percent'] === 100;
                        return (<Button key={data.id} onClick={() => {
                            props.setCurrentOrderPreparationItem(data)
                            props.setLock(true);
                            setUserSelectPreparationItem(true)
                        }}>
                            <SmallSquareData
                                bigger={props.currentOrderPreparationItem.id === data.id}
                                color={finished ? 'success' : 'danger'}
                                subTitle={data.quantityTotalToPrepare > 0 ? '' + data.quantityTotalToPrepare : 'OK'}
                                value={data.spot['spot']}/>
                            </Button>
                        )
                }
                )}
            </Box>
        </Box>
    );
}

export default BreadcrumbOrderPreparationItem
