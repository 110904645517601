import {useState} from "react";
import {Box} from "@mui/material";
import Lock from "./Lock";
import PaletteItemQuantity from "./PaletteItemQuantity";
import PaletteSelector from "./PaletteSelector";
import CreatePaletteItem from "./CreatePaletteItem";
import * as React from "react";

const PaletteItem = (props) => {
    const [loading, setLoading] = useState(false);
    const [quantityToPrepare, setQuantityToPrepare] = useState(0);

    const [palette, setPalette] = useState(props.palette == null ? (props.orderPreparation.palettes.length > 0 ? props.orderPreparation.palettes[props.orderPreparation.palettes.length - 1] : null) : props.palette);

    return (
        <>
            <Box key={'PaletteItem'+props.error} sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                marginTop: 2,
                marginBottom: 2
            }}>
                <Lock
                    lock={props.lock}
                    setLock={props.setLock}
                    paletteId={palette}
                    spot={props.orderPreparationItem.spot}
                    step='prepare'
                />
                <PaletteItemQuantity
                    loading={loading}
                    setLoading={setLoading}
                    orderPreparation={props.orderPreparation}
                    orderPreparationItem={props.orderPreparationItem}
                    setQuantityToPrepare={setQuantityToPrepare}
                    quantityToPrepare={quantityToPrepare}
                    palette={palette}
                    lock={props.lock}
                />

                <PaletteSelector
                    setPalette={setPalette}
                    palette={palette}
                    setLock={props.setLock}
                    setQuantityToPrepare={setQuantityToPrepare}
                    orderPreparation={props.orderPreparation}
                />
                <CreatePaletteItem
                    setLoading={setLoading}
                    quantityToPrepare={quantityToPrepare}
                    setQuantityToPrepare={setQuantityToPrepare}
                    orderPreparation={props.orderPreparation}
                    setOrderPreparation={props.setOrderPreparation}
                    orderPreparationItem={props.orderPreparationItem}
                    palette={palette}
                />
            </Box>
        </>
    )
};

export default PaletteItem
