import {useCallback, useMemo} from "react";
import {useDropzone} from "react-dropzone";
import {useNotify} from "react-admin";
import * as React from "react";
import dataProvider, {fetchData} from "../../../../../utils/dataProvider";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const ImportProductsPictures = (props) => {
    const notify = useNotify();
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const id = file.path.split('.')[0];
            const resourceId = '/' + props.resource + '/' + id

            dataProvider.getOne(props.resource, {
                id: resourceId
            }).then((resource) => {
                const FD  = new FormData();
                FD.append('file', file);

                fetchData('/media_objects', 'POST', FD).then((response) => {
                    return response.json()
                }).then((result) => {
                    let data = {};
                    data[props.target] = result['@id'];

                    dataProvider.update(props.resource, {
                        id: resourceId,
                        data: data
                    }).then(notify(`Ajout de l'image "${file.path}" pour la ressource ${resource.data.name}`, 'success'));
                })
            }).catch(() => {
                notify('Aucune ressource trouver avec pour code ' + id, 'error')
            })
        })
    }, [])
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive
    } = useDropzone({onDrop, accept: 'image/*'})

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Glissez les images ici ...</p> :
                    <p>Glissez et déposez vos images ici</p>
            }
        </div>
    )
};

export default ImportProductsPictures;
