import {Box, ListItemAvatar, ListItemText, Chip} from "@mui/material";
import plural from "pluralize-fr";
import SmallSquareData from "../../../component/presenter/square/SmallSquareData";
import * as React from "react";
import {useState} from "react";
import ImageField from "../../../component/field/ImageField"
import {library} from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
library.add(faCircleCheck, faCancel)

const ShowProduct = (props) => {
    const [displayed, setDisplayed] = useState(true);


    if(props.record == null)
        return null;

    
    var secondary = props.record.packagingType ? props.record.packagingType : ' '
        + props.record.packagingQuantity ? props.record.packagingQuantity : ' '
        + props.record.packagingUnit ? plural(props.record.packagingUnit).toLowerCase() : '';
    if(secondary == "0"){
        secondary = "";
    }

    const ChipTestAndDisplay = (props) =>{
        if(typeof props.custom !== 'undefined' && props.custom !== ""){
            return(<><br/><Chip label={props.custom} variant="outlined" /></>)
        }else if(typeof props.attributes !== 'undefined' && props.attributes !== ""){
            return(<><br/><Chip label={props.attributes} variant="outlined" /></>)
        }else{
            return(<></>);
        }
    }
 
    return (
        <>
            <ImageField source="image" style={{marginRight: "10px"}} />
            <ListItemText primary={<Box component="span" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>{props.record.name} <ChipTestAndDisplay custom={props.record.custom}/> <ChipTestAndDisplay custom={props.record.attributes}/></Box>} secondary={secondary} />
            <SmallSquareData sx={{marginTop: 0, marginLeft: 2, marginRight: 2}} bigger={true} color={"info"} subTitle={props.palette.quantityToString} value={props.palette.quantity}/>
        </>
    )
}

export default ShowProduct
