import * as React from 'react'
import {
    SingleFieldList,
    ReferenceArrayField,
    ReferenceField,
    TextField,
    ReferenceArrayInput,
    SelectArrayInput
} from "react-admin"
import {FormListGuesser} from "../../../form/List";
import BooleanField from "../../../field/Boolean";
import PaymentField from "../../../field/PaymentField";
import NumberField from "../../../field/NumberField";
import LinearProgressWithLabelField from "../../../field/LinearProgressWithLabelField";
import IdFieldGuesser from "../../../field/Id";
import OrderPreparationListButtonAction from "./List/ButtonAction";
import BulkNavetteDialogAction from "./List/BulkNavetteDialogAction";
import SpotPresenter from "../../../presenter/Spot";
import BulkPlanned from "./List/BulkPlanned";
import {ButtonGroup, InputLabel} from "@mui/material";
import ChannelAndOrderIdPresenter from "../../../presenter/ChannelAndOrderIdPresenter";
import BulkPriority from "./List/BulkPriority";
import BulkAssignment from "./List/BulkAssignment";
import UserAvatarPresenter from "../../../presenter/UserAvatar";
import StatePresenter from "../../../presenter/StatePresenter";
import NavetteField from "../../../field/NavetteField";
import { TextInput } from 'react-admin';


const BulkAction = () => {
    return (
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <BulkPlanned/>
            <BulkNavetteDialogAction/>
            <BulkPriority />
            <BulkAssignment />
        </ButtonGroup>
    )
}

const postFilters = [
    <TextInput label="N°" source="id" alwaysOn />,
    <ReferenceArrayInput source="channel" reference="channels" alwaysOn>
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>,
    <TextInput label="Id Commande" source="orderId" alwaysOn />,
    <ReferenceArrayInput source="state" reference="order_preparation_states">
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="step" reference="order_preparation_steps">
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="carrier" reference="carriers">
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
];

export default function OrderPreparationList(props) {
    return (
        <FormListGuesser {...props} filters={postFilters} removeBulkActionDelete={true} bulkActionButtons={<BulkAction />} hasShow={false}>
            <OrderPreparationListButtonAction eye={true}/>
            <IdFieldGuesser source="id"/>
            <ChannelAndOrderIdPresenter label='Canal' sortBy="channel.code"/>
            <ReferenceField record={props.record} reference={"order_preparation_states"} source={"state"} link={false} sortBy={'state.code'}>
                <StatePresenter/>
            </ReferenceField>
            <ReferenceField reference={"carriers"} source={"carrier"} link={false} sortBy="carrier.name">
                <TextField source="name"/>
            </ReferenceField>
            <PaymentField label="Paiement" />
            <NumberField source="priority" sortBy="priority"/>
            <LinearProgressWithLabelField source="progress" sortable={false}/>
            <ReferenceField reference={"users"} source={"assignment"} link={false} sortable={false}>
                <UserAvatarPresenter/>
            </ReferenceField>
            <ReferenceArrayField record={props.record} reference="users" source="usersHistories" sortable={false}>
                <SingleFieldList linkType={false} style={{flexWrap: 'nowrap'}}>
                    <UserAvatarPresenter type="list"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <SpotPresenter source='spot' sortable={false}/>
            <TextField source="totalProducts" sortBy="totalProducts" sortable={false}/>
            <TextField source="totalProductsQuantity" sortable={false}/>
            <TextField source="totalPalettes" sortable={false}/>
            <ReferenceArrayField reference="navettes" source="navettes" sortable={false}>
                <SingleFieldList>
                    <NavetteField />
                </SingleFieldList>
            </ReferenceArrayField>
        </FormListGuesser>
    )
}

