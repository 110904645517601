
import {Button, Tooltip} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

const BtnInfoPrep = (props) =>{
    
    let comment = props.data.comment;
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <>
        <Tooltip title="Informations ADV" arrow>
            <Button style={style} variant="contained" onClick={handleClickOpen} component="label" size="small">
                                <InfoIcon />
            </Button>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Commentaire ADV</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {comment}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </>
    )
}
export default BtnInfoPrep;