import * as React from 'react';
import {Menu, MenuItemLink} from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {forwardRef} from "react";
import MenuItem from "./MenuItem";
import MenuRole from "./MenuRole";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ReportIcon from '@mui/icons-material/Report';
import PlaceIcon from '@mui/icons-material/Place';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import {Divider} from "@mui/material";
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ApartmentIcon from '@mui/icons-material/Apartment';

const MenuItemPreparer = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref} style={{
        marginTop: 25,
        color: (window.location.href.includes('/prepare') ? '#000000de' : '#0000008a')
    }} to="/prepare" primaryText="Préparer" leftIcon={<AssignmentIcon/>} onClick={onClick}/>
));
const MenuItemControler = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/controler') ? '#000000de' : '#0000008a')}}
                  to="/controler" primaryText="Contrôler" leftIcon={<FactCheckIcon/>} onClick={onClick}/>
));
const MenuItemControlerResponsable = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref}
                  style={{color: (window.location.href.includes('/controler-responsable') ? '#000000de' : '#0000008a')}}
                  to="/controler-responsable" primaryText="Contrôler Responsable" leftIcon={<FactCheckIcon/>}
                  onClick={onClick}/>
));
const MenuItemEmballer = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/emballer') ? '#000000de' : '#0000008a')}}
                  to="/emballer" primaryText="Emballer" leftIcon={<AutoAwesomeMotionIcon/>} onClick={onClick}/>
));
const MenuItemCharger = forwardRef(({onClick}, ref) => (
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/charger') ? '#000000de' : '#0000008a')}}
                  to="/charger" primaryText="Charger" leftIcon={<LocalShippingIcon/>} onClick={onClick}/>
));

const AdminMenu = () => {
    return (

        <Menu>
            <MenuRole role={"ROLE_LOGISTICIEN"}>
                <MenuItemPreparer/>
                <MenuItemControler/>
            </MenuRole>

            <MenuRole role={"ROLE_RESPONSABLE"}>
                <MenuItemControlerResponsable/>
            </MenuRole>

            <MenuRole role={"ROLE_LOGISTICIEN"}>
                <MenuItemEmballer/>
                <MenuItemCharger/>
            </MenuRole>
            <Divider sx={{mt: 2, mb: 2}}/>
            <MenuRole role={"ROLE_RESPONSABLE"}>
                <MenuItem leftIcon={<GroupIcon/>} resource="users"/>
                <MenuItem leftIcon={<ShoppingCartIcon/>} resource="order_preparations"/>
                <MenuItem leftIcon={<BorderAllIcon/>} resource="palettes"/>
                <MenuItem leftIcon={<AirportShuttleIcon/>} resource="navettes"/>
                <MenuItem leftIcon={<CategoryIcon/>} resource="products"/>
                <MenuItem leftIcon={<WarehouseIcon/>} resource="warehouses"/>
                <MenuItem leftIcon={<PlaceIcon/>} resource="warehouse_locations"/>
                <MenuItem leftIcon={<ReportIcon/>} resource="user_souffrances"/>
                <MenuItem leftIcon={<LocalShippingIcon/>} resource="carriers"/>
            </MenuRole>
            <Divider sx={{mt: 2, mb: 2}}/>
            <MenuRole role={"ROLE_ADMIN"}>
                <MenuItem leftIcon={<GroupIcon/>} resource="users"/>
                <MenuItem leftIcon={<AssistantPhotoIcon/>} resource="order_preparation_states"/>
                <MenuItem leftIcon={<BuildIcon/>} resource="order_preparation_steps"/>
                <MenuItem leftIcon={<PlaceIcon/>} resource="warehouse_locations"/>
                <MenuItem leftIcon={<LocalShippingIcon/>} resource="carriers"/>
                <MenuItem leftIcon={<StorefrontIcon/>} resource="channels"/>
                <MenuItem leftIcon={<ApartmentIcon/>} resource="agencies"/>
                <MenuItem leftIcon={<BrandingWatermarkIcon/>} resource="brands"/>
            </MenuRole>
        </Menu>
    )
};

export default AdminMenu;
