import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import BootstrapDialogTitle from "./BootstrapDialogTitle";
import {CircularProgress, LinearProgress, MenuItem, Select, Tab, Tabs} from "@mui/material";
import {AddCircle, AddCircleOutlined} from "@mui/icons-material";
import {CreateGuesser} from "@api-platform/admin";
import {ReferenceField, ReferenceInput, SelectInput, TextField, useGetList, useNotify} from "react-admin";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import BootstrapDialog from "./BootstrapDialog";
import {useState} from "react";

const SelectNavette = (props) => {
    const {data, ids, loading, error} = useGetList(
        'navettes',
        {},
        {field: 'estimatedAt', order: 'ASC'}
    );
    if (loading || error) return <LinearProgress/>;

    return (
        <Select defaultValue="" placeholder={"Choisissez une navette"} sx={{width: '100%'}} onChange={props.onChange}>
            {ids.map((id) => (
                <MenuItem key={id} value={id}>
                    #{id.split('/')[2]} -&nbsp;
                    <ReferenceField reference={"carriers"} source={"carrier"} record={data[id]} link={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                    &nbsp;- {new Date(data[id].estimatedAt).toLocaleDateString()}
                </MenuItem>
            ))}
        </Select>
    )
}

export default function NavetteDialog(props) {
    const [currentNavette, setCurrentNavette] = useState(null);
    const [refreshDialog, setRefreshDialog] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const notify = useNotify();

    const handleClose = () => props.setOpen(false);
    const handleCreateNavette = () => {
        setRefreshDialog(!refreshDialog)
        notify('Nouvelle navette créée', {type: 'success'})
        setValueTab(0)
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Attribuer une navette
            </BootstrapDialogTitle>

            <Tabs value={valueTab} onChange={(event, newValue) => {
                setValueTab(newValue);
                setCurrentNavette(null)
            }} aria-label="icon label tabs example">
                <Tab icon={<AddCircleOutlined/>} label="ATTRIBUER"/>
                <Tab icon={<AddCircle/>} label="CREER"/>
            </Tabs>
            {
                valueTab === 0 &&
                <DialogContent key={'bulkNavetteDialogSelect' + refreshDialog} dividers>
                    <SelectNavette onChange={(event) => setCurrentNavette(event.target.value)}/>
                </DialogContent>
            }

            {
                valueTab === 1 &&
                <DialogContent key={'bulkNavetteDialogCreate' + refreshDialog} dividers>
                    <CreateGuesser
                        redirect={false}
                        resource={'navettes'}
                        onSuccess={handleCreateNavette}
                    >
                        <ReferenceInput source={"carrier"} reference={"carriers"}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <InputGuesser source={"estimatedAt"}/>
                    </CreateGuesser>
                </DialogContent>
            }

            <DialogActions>
                <Button startIcon={props.loading ? <CircularProgress/> : null} autoFocus variant="contained"
                        color="primary" disabled={props.loading || currentNavette == null} onClick={() => props.handleValidation(currentNavette)}>
                    Valider
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}